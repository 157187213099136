import { Alert, Button, Divider, Grid, NumberInput, Select, Stack, Text, Title, useMantineTheme } from "@mantine/core"
import { DatePicker } from "@mantine/dates";
import { useState } from "react";

export const NewHabit = ( { onNextStep } ) => {

    const amounts = [ 10, 20, 50, 100 ];
    const frequencies = [ 'Daily', 'Weekly', 'Bi-Weekly', 'Monthly' ];

    const [frequency, setFrequency] = useState(frequencies[0]);
    const [startDate, setStartDate] = useState(null);

    return (
        <>
            <Stack spacing="lg">

                <Stack spacing="md">
                    <NumberInput
                        size="xl"
                        hideControls
                        defaultValue={10}
                        rightSectionWidth={90}
                        rightSection={
                            <Button >Max</Button>
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        formatter={(value) =>
                            !Number.isNaN(parseFloat(value))
                            ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            : '$ '
                        }
                        />

                    <Alert color="gray" radius={'md'}><Text align="center" size="xs" color='dark'>Minimum purchase of $10. Maximum purchase of $700.</Text></Alert>

                    <Grid gutter="sm">
                        {amounts.map(amount => 
                        <Grid.Col span={amounts.length-1}>
                            <Button size="md" fullWidth>${amount}</Button>
                        </Grid.Col>)}
                    </Grid>
                </Stack>

                <Divider />

                <Grid gutter="md">

                    <Grid.Col span={6}>
                        <Select
                            label="Frequency"
                            placeholder="Daily"
                            value={frequency}
                            onChange={(freq) => setFrequency(freq)}
                            data={frequencies}
                            size="md"
                            />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <DatePicker
                            label="Start Date"
                            placeholder="Pick a date"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            size="md"
                            />
                    </Grid.Col>
                </Grid>
                        
                <Button size="md" fullWidth onClick={onNextStep}>Continue</Button>

            </Stack>
        </>
    )
}
