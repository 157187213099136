import { Checkbox, Divider, SimpleGrid, Stack, Title, Text, Button } from "@mantine/core"

export const HabitReceipt = ({ onNextStep }) => {
  return (
    <>
            <Stack spacing="lg">

                <Stack spacing='sm'>
                    <Title style={{ fontsize: '4rem' }}align="center">🌱</Title>
                    <Title order={2} align="center">Almost There</Title>
                    <Text align="center" size="lg">You will save $10 every day.</Text>
                </Stack>

                <Stack spacing='sm'>

                    <SimpleGrid gutter="xs" cols={2}>

                        <Stack spacing="xs" >
                            <Text align="left" color='gray'>Schedule</Text>
                            <Text align="left" color='gray'>Amount</Text>
                        </Stack>

                        <Stack spacing="xs" >
                            <Text align="right">Daily</Text>
                            <Text align="right">$10.00</Text>
                        </Stack>

                    </SimpleGrid>

                    <Divider />

                    <SimpleGrid gutter="xs" cols={2}>

                        <Stack spacing="xs" >
                            <Text align="left" color='gray'>Fee (1%)</Text>
                            <Text align="left" color='gray'>Weekly transaction amount*</Text>
                            <Text align="left" color='gray'>Total</Text>
                        </Stack>

                        <Stack spacing="xs" >
                            <Text align="right">$1.00</Text>
                            <Text align="right">$70.00</Text>
                            <Text align="right">$71.00</Text>
                        </Stack>

                    </SimpleGrid>

                </Stack>

                <Checkbox radius='xs'
                label="*Your first transfer of $71 wil be initiated on the day above. After that, $71 will be transferred every week, and bitcoin will be purchased daily."
                />
                        
                <Button size="md" fullWidth onClick={onNextStep}>Confirm</Button>

            </Stack>
        </>
  )
}
