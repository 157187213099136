import { Routes,Route,useLocation } from 'react-router-dom';
import { ModalRoute } from '../components/modals/ModalRoute';
import { Error } from '../pages/Error';
import { Index } from '../pages/Index';
import { NewSavingHabit } from '../pages/saving-habits/NewSavingHabit';


export const Router = () => {

  const location = useLocation();

  const state = location.state;

  return (
    <>
      <Routes location={state?.backgroundLocation || location}>

        <Route path="/" element={<Index />}/>

        {/* SAVING HABITS */}
        <Route path="/saving-habit/new" element={<NewSavingHabit />}/>

        <Route path="*" element={<Error />}/>

      </Routes>

      {/* Show the modal when a `backgroundLocation` is set */}
      {state?.backgroundLocation && (

        <Routes>
          <Route path="/saving-habit/new" element={<ModalRoute><NewSavingHabit /></ModalRoute>} />
        </Routes>

      )}

    </>
  )
}
