import { Button, Title } from "@mantine/core"
import { Link,useLocation } from "react-router-dom";


export const Index = () => {
    
    const location = useLocation();

    return (
        <div>
            <Title>Coinbits components test</Title>
            <Button component={Link} to="/saving-habit/new" size="md" state={{ backgroundLocation: location }}>Saving Habit</Button>
        </div>
    )
}
