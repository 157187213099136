import { useEffect,useState } from "react";
import { Modal } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const ModalRoute = ({ children }) => {

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setTimeout(setIsOpen(true),1);
    },[]);

    const onDismiss = () => {
        setIsOpen(false);
        setTimeout(() => navigate(-1),300);
    }

    return (
        <Modal
            style={{ inner: { padding: 0 }, }}
            withCloseButton={false}
            transition={'slide-up'}
            transitionDuration={300}
            transitionTimingFunction={'cubic-bezier(0,.7,.3,.1)'}
            overflow="outside"
            opened={isOpen}
            onClose={onDismiss}
            overlayOpacity={0.55}
            radius={'lg'}
            size={'md'}
        >
        { children }
        </Modal>
    )
}
