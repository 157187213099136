import { ActionIcon, Button, Grid, Group, Stack, Stepper, Title } from "@mantine/core"
import { HiArrowSmLeft } from "react-icons/hi"
import { useState } from "react";
import { NewHabit } from "../../components/saving-habits/NewHabit"
import { HabitReceipt } from "../../components/saving-habits/HabitReceipt";

export const NewSavingHabit = ({ onCloseModal }) => {

    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    return (
        <div>
            <Stack spacing="sm">
            <Group position="apart" >
                <ActionIcon variant="default" size="lg" onClick={prevStep}>
                    <HiArrowSmLeft size={24} color='dark'/>
                </ActionIcon>
                <Title order={3} align="center">Saving Habit</Title>
                <ActionIcon variant="transparent" size="lg"></ActionIcon>
            </Group>
                
                <Stepper 
                    active={active} 
                    onStepClick={setActive} 
                    breakpoint="sm"
                    styles={{steps: { display: 'none' }}}
                    >
                    
                    <Stepper.Step>
                        <NewHabit onNextStep={nextStep}/>
                    </Stepper.Step>

                    <Stepper.Step label="Second step" description="Verify email">
                        <HabitReceipt onNextStep={nextStep}/>
                    </Stepper.Step>
                    
                    <Stepper.Completed>
                    Completed, click back button to get to previous step
                    </Stepper.Completed>

                </Stepper>
            </Stack>
            
        </div>
    )
}
