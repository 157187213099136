export const theme = {
    
    fontFamily: 'sofia-pro, sans-serif',
    headings: { 
        fontFamily: 'sofia-pro, sans-serif',
        fontWeight: '600'
    },
    focusRing: 'never',
    colorScheme: 'light',
    black: '#152536',
    colors: {
        'green': ['#e7faf3','#cbf4e5','#b0eed7','#94e8c9','#78e2bb','#5cdcad','#40d69f','#37b788','#2d9871','#247859'],
        'orange': ['#fff4eb','#ffe7d3','#ffdabb','#ffcca3','#ffbf8c','#ffb274','#ffa55c','#da8d4f','#b57541','#8f5d34'],
        'black': ['#e8ebef','#ccd4db','#b1bcc8','#95a5b4','#7a8da1','#5e768d','#435e7a','#395068','#2f4356','#263545'],
        'gray': ['#F6F8FA','#ECF0F5','#E1E8EF','#d7e0ea','#B7C8D9','#9CABB9','#b7c8d9','#9cabb9','#828e9a','#67707a'],
        'red': ['#fde3e6','#fac3c8','#f7a3ab','#f4828d','#f16270','#ee4152','#eb2135','#c91c2d','#a61726','#84131e'],
        'yellow': ['#fff8e4','#fff0c3','#ffe8a3','#ffe083','#ffd863','#ffd042','#ffc822','#daab1d','#b58e18','#8f7013'],
        'blue': ['#e1eff9','#bdddf3','#99cbec','#75b8e5','#52a6de','#2e93d8','#0a81d1','#096eb2','#075b94','#064875']
    },
    spacing: { xs: 5, sm: 10, md: 20, lg: 30, xl: 40 },
    radius: { xs: 6, sm: 8, md: 10, lg: 12, xl: 14 },
    primaryColor: 'green'
    
}